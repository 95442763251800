export default {
  created: 'created',
  payment: 'payment',
  review: 'review',
  placed: 'placed',
  preparation: 'preparation',
  ready: 'ready',
  delivery: 'delivery',
  next_delivery: 'next_delivery',
  arriving: 'arriving',
  done: 'done',
  failed: 'failed',
  cancelled: 'cancelled'
};
